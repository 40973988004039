<template>
  <div>
    <b-sidebar
      id="add-new-patient-sidebar"
      :visible="isAddNewPatientSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="val => $emit('update:is-add-new-patient-sidebar-active', val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ $t('Add') }} {{ $t('NewV2') }} {{ $t('Patient') }}</h5>
          <i class="fas fa-times ml-1 cursor-pointer" size="16" @click="hide"></i>
        </div>

        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm">
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required">
              <b-form-group :label="$t('Form.Name')" label-for="name">
                <b-form-input
                  id="name"
                  v-model="stateData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="First Name" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required">
              <b-form-group :label="$t('Form.Last Name')" label-for="lastname">
                <b-form-input
                  id="lastname"
                  v-model="stateData.lastname"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Last Name" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Phone -->
            <validation-provider
              #default="validationContext"
              name="Phone"
              rules="required|max:14">
              <b-form-group :label="$t('Form.Phone')" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="formatPhone"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Gender -->
            <validation-provider name="Gender" rules="required">
              <b-form-group :label="$t('Form.Gender')" label-for="gender">
                <treeselect
                  id="gender"
                  v-model="stateData.gender"
                  :options="genderOptions"
                  :normalizer="normalizer" />
                <b-form-invalid-feedback>
                  {{ $t('This field is required') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Type visit -->
            <validation-provider
              #default="validationContext"
              name="Type visit"
              rules="required">
              <b-form-group label-for="type_visit">
                <label for="type_visit">{{ $t('Type visit') }}</label>
                <treeselect
                  id="type_visit"
                  v-model="stateData.type_visits_id"
                  :state="getValidationState(validationContext)"
                  :options="optionsTypeVisit"
                  :normalizer="normalizer" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Name insurance -->
            <validation-provider
              #default="validationContext"
              name="Name Insurance"
              rules="required">
              <b-form-group label-for="name_insurance">
                <label for="name_insurance">{{ $t('Name insurance') }}</label>
                <treeselect
                  id="name_insurance"
                  v-model="stateData.nameInsuranceId"
                  :state="getValidationState(validationContext)"
                  :options="optionsNameInsurance"
                  :normalizer="normalizer" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Conditional Content -->
            <div v-if="showConditionalContent">
              <membership @dataMemberchip="handleDataMemberchip" />
            </div>

            <!-- Visit Reason -->
            <validation-provider
              #default="validationContext"
              name="Visit Reason"
              rules="required">
              <b-form-group label-for="visit_reason">
                <label for="visit_reason">{{ $t('Visit Reason') }}</label>
                <b-form-textarea
                  v-model="stateData.reason_visit"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Reference -->
            <validation-provider
              v-if="showConditionalContentRef"
              #default="validationContext"
              name="Reference"
              rules="required">
              <b-form-group label-for="reference">
                <label for="reference">{{ $t('Reference') }}</label>
                <treeselect
                  id="reference"
                  v-model="stateData.user_references_id"
                  :state="getValidationState(validationContext)"
                  :options="optionsReferenceList"
                  :normalizer="normalizer" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div>
              <validation-provider
                #default="validationContext"
                name="Copay"
                rules="required|min_value:0|numeric">
                <b-form-group :label="$t('Copay')" label-for="copay">
                  <b-form-input
                    id="copay"
                    v-model="stateData.copay"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Copay"
                    min="0" />
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Co Insurance"
                rules="required|min_value:0|numeric">
                <b-form-group :label="$t('CoInsurance')" label-for="co_insurance">
                  <b-form-input
                    id="co_insurance"
                    v-model="stateData.co_insurance"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Co Insurance"
                    min="0" />
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Deducible"
                rules="required|min_value:0|numeric">
                <b-form-group :label="$t('Deducible')" label-for="deducible">
                  <b-form-input
                    id="deducible"
                    v-model="stateData.deducible"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Deducible"
                    min="0" />
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Out Pocket"
                rules="required|min_value:0|numeric">
                <b-form-group :label="$t('OutPocket')" label-for="out_pocket">
                  <b-form-input
                    id="out_pocket"
                    v-model="stateData.out_pocket"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Out Pocket"
                    min="0" />
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <!-- Date of Birth -->
            <validation-provider #default="validationContext" name="DOB" rules="required">
              <b-form-group label="DOB" label-for="date-birth">
                <flat-pickr
                  v-model="stateData.date_birth"
                  :config="config"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  placeholder="MM-DD-YYYY"
                  :class="{
                    'is-invalid': validationContext.touched && !validationContext.valid,
                  }" />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Cupon
            <checkCupon :cupon.sync="stateData.cupon" @cancelCupon="cancelCupon" /> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                <span v-if="!loading">{{ $t('Add') }}</span>
                <span v-else><SpinnerLoading /></span>
              </b-button>
              <b-button type="button" variant="outline-danger" @click="hide">{{
                $t('Cancel')
              }}</b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import formValidation from '@/core/utils/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SpinnerLoading from '@/components/SpinnerLoading'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import ToastNotification from '@/components/ToastNotification'
import checkCupon from '@/components/checkCupon'
import membership from './MemberchipAdd.vue'
import { notificationCheck } from '@/mixins/NotificationSetup'
import axiosP from '@/core/services/apiInsurance/patient'
import axiosTV from '@/core/services/apiInsurance/admin/consults/visits'
import axiosNI from '@/core/services/apiInsurance/admin/consults/insuranceName'
import axiosA from '@/core/services/apiInsurance/appointment/actions'

export default {
  components: {
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ToastNotification,
    BButton,
    SpinnerLoading,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    // checkCupon,
    membership,
  },
  model: {
    prop: 'isAddNewPatientSidebarActive',
    event: 'update:is-add-new-patient-sidebar-active',
  },
  props: {
    isAddNewPatientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      genderOptions: [
        { name: 'Male', id: 'male' },
        { name: 'Female', id: 'female' },
      ],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
    }
  },
  setup(props, { emit }) {
    const stateDataTem = {
      name: '',
      lastname: '',
      phone: '',
      date_birth: '',
      cupon: '',
      type_visits_id: null,
      gender: null,
      codeQr: null,
      user_references_id: null,
      nameInsuranceId: null,
      copay: 0,
      co_insurance: 0,
      deducible: 0,
      out_pocket: 0,
    }

    const toast = ref(null)
    const loading = ref(false)
    const inputScan = ref(null)
    const checkCodeQr = ref(false)
    const optionsTypeVisit = ref([])
    const optionsNameInsurance = ref([])
    const clearTimeoutBuscador = ref('')
    const optionsReferenceList = ref([])
    const isAddNewConsultSidebarActive = ref(false)
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)))
    const membershipData = ref(null)

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem))
    }

    const cancelCupon = () => {
      stateData.value.cupon = ''
    }

    watch(
      () => stateData.value.codeQr,
      newValue => {
        if (newValue !== null || newValue.length > 0) {
          const data = { codeQr: stateData.value.codeQr }
          axiosA.appointmentAssignCuppon(data).then(res => {
            const { type, message } = notificationCheck(res)
            toast.value[type](message)
          })
        }
      }
    )

    const showConditionalContent = computed(() => {
      return stateData.value.type_visits_id === 8
    })

    const showConditionalContentRef = computed(() => {
      return stateData.value.type_visits_id === 2 || stateData.value.type_visits_id === 3
    })

    const scanPromotion = () => {
      if (checkCodeQr.value) {
        stateData.value.codeQr = null
      }
      checkCodeQr.value = !checkCodeQr.value
    }

    const formatPhone = computed({
      get() {
        let { phone } = stateData.value
        phone = phone.toString()
        const match = phone
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        return match
      },
      set(val) {
        stateData.value.phone = val
      },
    })

    const createConsult = () => {
      loadTypeVisit()
      isAddNewConsultSidebarActive.value = false
    }

    const searchTypeVisit = (name, loading) => {
      if (name.length) {
        const datos = { name: name, status: true }
        loading(true)
        clearTimeout(clearTimeoutBuscador.value)
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosTV.visitFilter(10, datos).then(({ data }) => {
            loading(false)
            optionsTypeVisit.value = data
          })
        }, 400)
      }
    }

    onMounted(() => {
      loadTypeVisit()
      loadReferencesList()
      loadNameInsurance()
    })

    const loadTypeVisit = () => {
      axiosTV.visitList(20).then(({ data }) => {
        optionsTypeVisit.value = data
      })
    }

    const loadNameInsurance = () => {
      axiosNI.visitList(20).then(({ data }) => {
        optionsNameInsurance.value = data
      })
    }

    const loadReferencesList = () => {
      axiosP.referencePagination(10, 1).then(({ data }) => {
        optionsReferenceList.value = data.data
      })
    }

    const handleDataMemberchip = dato => {
      membershipData.value = dato
    }

    const onSubmit = async () => {
      loading.value = true
      const phone = stateData.value.phone.replace(/[() -]/g, '')
      const datos = {
        ...stateData.value,
        phone,
        ...membershipData.value,
        type_membership: 'NP',
      }
      await axiosP
        .patientCreate(datos)
        .then(res => {
          const { type, message } = notificationCheck(res)
          emit('createPatient', { type, message })
          loading.value = false
          resetuserData()
        })
        .catch(error => {
          loading.value = false
          const { type, message } = notificationCheck(error.response)
          emit('createPatient', { type, message })
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      loading,
      stateData,
      formatPhone,
      toast,
      checkCodeQr,
      inputScan,
      scanPromotion,
      onSubmit,
      isAddNewConsultSidebarActive,
      createConsult,
      optionsTypeVisit,
      optionsNameInsurance,
      searchTypeVisit,
      optionsReferenceList,
      refFormObserver,
      getValidationState,
      resetForm,
      config: {
        dateFormat: 'm-d-Y',
        allowInput: true,
        static: true,
      },
      cancelCupon,
      showConditionalContent,
      showConditionalContentRef,
      handleDataMemberchip,
      membershipData,
    }
  },
}
</script>

<style lang="scss" scoped>
.button-custom {
  padding: 5px !important;
}

.flatpickr-wrapper {
  width: 100% !important;
}
</style>
